import type { INavigation } from './types';

const RIGHT_ARROW_ICON = () => (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      strokeWidth="1.5"
      stroke="#141B34"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1.00005 1C1.00005 1 6.99999 5.41893 7 7.00005C7.00001 8.58116 1 13 1 13"
    />
  </svg>
);

export const navigation: INavigation = {
  tabs: [
    { name: 'Lahiyə haqqında', href: '/about-project', icon: RIGHT_ARROW_ICON },
    // { name: 'Bizimlə əlaqə', href: '#', icon: RIGHT_ARROW_ICON },
    // { name: 'Banklar', href: '#', icon: RIGHT_ARROW_ICON },
    // { name: 'Xəbərlər', href: '#', icon: RIGHT_ARROW_ICON },
    // { name: 'Sual cavab', href: '#', icon: RIGHT_ARROW_ICON },
  ],
  social: [
    {
      name: 'Instagram',
      href: '#',
      icon: (props: any) => (
        <svg
          width="22"
          height="22"
          fill="none"
          viewBox="0 0 22 22"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeWidth="1.5"
            stroke="#141B34"
            strokeLinejoin="round"
            d="M1.5 11C1.5 6.52166 1.5 4.28249 2.89124 2.89124C4.28249 1.5 6.52166 1.5 11 1.5C15.4783 1.5 17.7175 1.5 19.1088 2.89124C20.5 4.28249 20.5 6.52166 20.5 11C20.5 15.4783 20.5 17.7175 19.1088 19.1088C17.7175 20.5 15.4783 20.5 11 20.5C6.52166 20.5 4.28249 20.5 2.89124 19.1088C1.5 17.7175 1.5 15.4783 1.5 11Z"
          />
          <path
            strokeWidth="1.5"
            stroke="#141B34"
            d="M15.5 11C15.5 13.4853 13.4853 15.5 11 15.5C8.51472 15.5 6.5 13.4853 6.5 11C6.5 8.51472 8.51472 6.5 11 6.5C13.4853 6.5 15.5 8.51472 15.5 11Z"
          />
          <path
            strokeWidth="2"
            stroke="#141B34"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.5078 5.5L16.4988 5.5"
          />
        </svg>
      ),
    },
    {
      name: 'Facebook',
      href: '#',
      icon: (props: any) => (
        <svg
          width="22"
          height="22"
          fill="none"
          viewBox="0 0 22 22"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeWidth="1.5"
            stroke="#141B34"
            strokeLinejoin="round"
            d="M1.5 11C1.5 6.52166 1.5 4.28249 2.89124 2.89124C4.28249 1.5 6.52166 1.5 11 1.5C15.4783 1.5 17.7175 1.5 19.1088 2.89124C20.5 4.28249 20.5 6.52166 20.5 11C20.5 15.4783 20.5 17.7175 19.1088 19.1088C17.7175 20.5 15.4783 20.5 11 20.5C6.52166 20.5 4.28249 20.5 2.89124 19.1088C1.5 17.7175 1.5 15.4783 1.5 11Z"
          />
          <path
            fill="#141B34"
            d="M16 7.75C16.4142 7.75 16.75 7.41421 16.75 7C16.75 6.58579 16.4142 6.25 16 6.25V7.75ZM15 7V7.75V7ZM10.25 20.5C10.25 20.9142 10.5858 21.25 11 21.25C11.4142 21.25 11.75 20.9142 11.75 20.5H10.25ZM9 12.25C8.58579 12.25 8.25 12.5858 8.25 13C8.25 13.4142 8.58579 13.75 9 13.75V12.25ZM14 13.75C14.4142 13.75 14.75 13.4142 14.75 13C14.75 12.5858 14.4142 12.25 14 12.25V13.75ZM16 6.25H15V7.75H16V6.25ZM10.25 11V20.5H11.75V11H10.25ZM15 6.25C14.0784 6.25 13.3117 6.24841 12.7055 6.32991C12.0777 6.41432 11.5109 6.59999 11.0555 7.05546L12.1161 8.11612C12.2464 7.9858 12.4439 7.87858 12.9054 7.81654C13.3884 7.75159 14.036 7.75 15 7.75V6.25ZM11.75 11C11.75 10.036 11.7516 9.38843 11.8165 8.90539C11.8786 8.44393 11.9858 8.24643 12.1161 8.11612L11.0555 7.05546C10.6 7.51093 10.4143 8.07773 10.3299 8.70552C10.2484 9.31174 10.25 10.0784 10.25 11H11.75ZM9 13.75H14V12.25H9V13.75Z"
          />
        </svg>
      ),
    },
    {
      name: 'Telegram',
      href: '#',
      icon: (props: any) => (
        <svg
          width="22"
          height="20"
          fill="none"
          viewBox="0 0 22 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke="#141B34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.9854 13.4083L14.2268 17.0936C15.4277 18.4589 16.0282 19.1416 16.6567 18.9754C17.2852 18.8092 17.5008 17.9108 17.9318 16.1138L20.3229 6.1459C20.9868 3.37832 21.3187 1.99454 20.5808 1.312C19.843 0.629466 18.564 1.13725 16.0061 2.15282L4.13876 6.86449C2.09293 7.67674 1.07001 8.08287 1.00507 8.78084C0.998423 8.85223 0.998313 8.92412 1.00474 8.99554C1.06754 9.69372 2.08921 10.1033 4.13255 10.9223C5.05838 11.2934 5.5213 11.479 5.8532 11.8344C5.89052 11.8743 5.9264 11.9157 5.96078 11.9584C6.26658 12.3384 6.39709 12.8371 6.65808 13.8344L7.14653 15.701C7.4005 16.6715 7.52749 17.1568 7.86008 17.223C8.19267 17.2891 8.48225 16.8867 9.06139 16.0819L10.9854 13.4083ZM10.9854 13.4083L10.6676 13.0771C10.3059 12.7001 10.1251 12.5117 10.1251 12.2775C10.1251 12.0433 10.3059 11.8548 10.6676 11.4778L14.2406 7.75409"
          />
        </svg>
      ),
    },
  ],
};
