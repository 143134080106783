import { useRouter } from 'next/navigation';

import { navigation } from './consts';

const Footer = () => {
  const router = useRouter();

  return (
    <footer className="bg-white lg:px-0 xxs:px-4 md:px-4" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-[1048px] mx-auto pb-8 pt-8">
        <div className="flex lg:justify-between lg:items-center lg:flex-row xxs:justify-start xxs:flex-col xxs:gap-3">
          <div className="flex flex-col gap-3">
            <h3 className="font-[600] text-[24px] cursor-pointer text-[#091012]" onClick={() => router.push('/')}>
              SmartBranch
            </h3>
            <div className="flex flex-col gap-2 text-[#6E757C]">
              <div className="flex gap-1">
                <span className="font-semibold">Email:</span>
                <span className="text-[#04a4d1]">smartbranch.office@gmail.com</span>
              </div>
              <div className="flex gap-1">
                <span className="font-semibold">Mobil nömrə: </span>
                <span className="text-[#082b4b]">050 666 94 95</span>
              </div>
            </div>
          </div>
          <div className="flex lg:gap-8 lg:flex-row xxs:flex-col xxs:gap-2 self-start">
            {navigation.tabs.map(item => (
              <div
                key={item.name}
                className="flex gap-2.5 items-center"
                onClick={() => router.push('about-project')}
                style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
              >
                <span className="text-gray-900 text-base">{item.name}</span>
                <item.icon />
              </div>
            ))}
          </div>
        </div>
        <div className="mt-8 border-t  border-gray-900/10 lg:pt-12 xxs:pt-6 md:flex xxs:flex md:justify-between flex-col gap-6">
          <div className="flex flex-col md:items-end lg:gap-x-2 xxs:gap-x-4">
            <div className="flex space-x-6 md:order-2">
              {navigation.social.map(item => (
                <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="flex justify-between lg:flex-row xxs:flex-col xxs:gap-4">
            <p className="text-base leading-5 text-gray-500 md:mt-0">
              © 2024 SmartBranch. Bütün hüquqlar qorunur.
            </p>
            <div className="flex gap-8 text-[#6E757C]">
              <span onClick={() => router.push('user-contract')} className="cursor-pointer">
                İstifadəçi Müqaviləsi
              </span>
              <span onClick={() => router.push('privacy-policy')} className="cursor-pointer">
                Məxfilik Siyasəti
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
