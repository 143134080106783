/**
 * ! Example for use react query on client component using hydrate from server
 */
'use client';

import { useState } from 'react';

import { Banner, TopOffers, CreditSearchForm } from '@/components/defaults';

import { Footer, Header, PageLayout } from '@/components/common';

const Home = () => {
  const [currentTab, setCurrentTab] = useState<string>('cash');

  return (
    <div>
      <Header />
      <PageLayout>
        <Banner {...{ currentTab }} />
        <CreditSearchForm {...{ setCurrentTab, currentTab }} />
        <TopOffers />
      </PageLayout>
      <Footer />
    </div>
  );
};

export default Home;
